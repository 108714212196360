<template>
  <microsoft-products />
</template>

<script>
import MicrosoftProducts from "../../components/solutions/volume-licensing/MicrosoftProducts.vue";
export default {
  name: "microsoft-products-page",
  components: { MicrosoftProducts },
};
</script>
